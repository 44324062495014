<template>
    <div
        class="badge"
        :class="{ 'badge--can-remove' : canRemove }"
        :style="{
            backgroundColor: bgColor,
            color: textColor
        }"
    >
        <slot />
        <to-icon-button
            v-if="canRemove"
            class="sz-1"
            @click="$emit('remove')"
        >
            <to-icon name="ex" />
        </to-icon-button>
    </div>
</template>

<script>
export default {
    name: 'Badge',
    props: {
        bgColor: {
            type: String,
            default: null
        },
        textColor: {
            type: String,
            default: null
        },
        canRemove: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 5px;
    border-radius: 5px;
    width: fit-content;

    &--can-remove {
        padding-right: 2px;
    }

    svg {
        width: 12px;
    }

    .icon-button {
        width: 17px;
        height: 17px;
        margin-left: 2px;
    }
}
</style>