import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { db } from '@/utility/db';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    convosEnabled: false,
    attachedConvoEnabled: true,
    convosWidth: 330,
    halfHalfWidth: 500,
    showSidebar: true,
    showCompleted: false,
    expandView: true,
    darkMode: false,
    darkModeFollowsOS: true,
    collaborators: [],
    orbitsRecents: [],
    peopleRecents: [],
    accountAvatars: {},
    orgAvatars: {},
    lastUpdated: {},
    savedState: {
      hideSubtasks: false,
      dismissTrialWarning: false,
      showConvoInputStyling: false,
      emojiSkintoneModifier: 0,
      newTaskPrefill: null,
      itemAttachmentsLayout: 'grid',
      // Whiteboard settings
      disableGrid: false,
      reduceMotion: false,
      isLocked: false,
      disableSnapping: false,
      disableEdgeScrolling: false,
      wrapMode: false,
      disableMotion: false
    },
    selectedMicrophone: null,
    selectedCamera: null,
    selectedSpeaker: null,
  },
  plugins: [
    createPersistedState({
      paths: [
        'isLoggedIn',
        'convosEnabled',
        'attachedConvoEnabled',
        'convosWidth',
        'halfHalfWidth',
        'showSidebar',
        'showCompleted',
        'expandView',
        'darkMode',
        'darkModeFollowsOS',
        'orbitsRecents',
        'peopleRecents',
        'lastUpdated',
        'savedState',
        'selectedMicrophone',
        'selectedCamera',
        'selectedSpeaker'
      ],
    }),
  ],
  mutations: {
    saveState(state, {key, value}) {
      Vue.set(state.savedState, key, value);
    },
    isLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    logIn() {
      state.isLoggedIn = true;
      document.cookie = `token=true; Domain=taskorbiter.com; path=/;`;
    },
    logOut(state) {
      state.isLoggedIn = false;
      document.cookie = `token=false; Domain=taskorbiter.com; path=/;`;

      state.collaborators = [];
      state.lastUpdated = {};
      db.items.clear();
      if (db.favorites) db.favorites.clear();
      if (db.lists) db.lists.clear();
      if (db.users) db.users.clear();
      if (db.myTasks) db.myTasks.clear();
      if (db.topics) db.topics.clear();

      axios.post('/api/auth/logout', null, {
        withCredentials: true
      })
        .then(() => {})
        .catch(() => {});
    },
    setAttachedConvoEnabled(state, enabled) {
      state.attachedConvoEnabled = enabled;
    },
    setConvosEnabled(state, enabled) {
      state.convosEnabled = enabled;
    },
    setConvosWidth(state, width) {
      state.convosWidth = width;
    },
    setHalfHalfWidth(state, width) {
      state.halfHalfWidth = width;
    },
    setShowSidebar(state, enabled) {
      state.showSidebar = enabled;
    },
    setShowCompleted(state, enabled) {
      state.showCompleted = enabled;
    },
    setExpandView(state, enabled) {
      state.expandView = enabled;
    },
    // New mutations for devices
    setSelectedMicrophone(state, deviceId) {
      state.selectedMicrophone = deviceId;
    },
    setSelectedCamera(state, deviceId) {
      state.selectedCamera = deviceId;
    },
    setSelectedSpeaker(state, deviceId) {
      state.selectedSpeaker = deviceId;
    },
    addCollaborator(state, data) {
      const index = state.collaborators
        .findIndex((collaborator) => collaborator.sid === data.sid && collaborator.id === data.id);

      if (index === -1) {
        state.collaborators.push(data);
      }
    },
    removeCollaborator(state, data) {
      const index = state.collaborators
        .findIndex((collaborator) => collaborator.sid === data.sid && collaborator.id === data.id);

      if (index !== -1) state.collaborators.splice(index, 1);
    },
    disconnectCollaborator(state, data) {
      state.collaborators = state.collaborators
        .filter((collaborator) => collaborator.sid !== data.sid);
    },
    setDarkMode(state, data) {
      state.darkModeFollowsOS = data.darkModeFollowsOS;
      state.darkMode = data.darkMode;
    },
    addRecent(state, data) {
      const name = `${data.app}Recents`;

      const index = state[name]
        .findIndex((recent) => recent.id === data.id);
      if (index !== -1) state[name].splice(index, 1);

      state[name].unshift({
        id: data.id,
        wid: data.wid,
      });

      const keep = state[name]
        .filter((recent) => recent.wid === data.wid)
        .slice(0, 3);

      state[name] = state[name]
        .filter((recent) => keep.includes(recent) || recent.wid !== data.wid);
    },
    updateAccountAvatar(state, { key, blob }) {
      Vue.set(state.accountAvatars, key, blob);
    },
    updateOrgAvatar(state, { key, blob }) {
      Vue.set(state.orgAvatars, key, blob);
    },
    updateLastUpdated(state, { oid, date }) {
      Vue.set(state.lastUpdated, oid, date);
    }
  },
  actions: {
    isLoggedIn({ commit }, data) {
      commit('isLoggedIn', data);
    },
    saveState({ commit }, {key, value}) {
      commit('saveState', {key, value});
    },
    logIn({ commit }) {
      commit('logIn');
    },
    logOut({ commit }) {
      commit('logOut');
    },
    setConvosEnabled({ commit }, data) {
      commit('setConvosEnabled', data);
    },
    setAttachedConvoEnabled({ commit }, data) {
      commit('setAttachedConvoEnabled', data);
    },
    setConvosWidth({ commit }, data) {
      commit('setConvosWidth', data);
    },
    setHalfHalfWidth({ commit }, data) {
      commit('setHalfHalfWidth', data);
    },
    setShowSidebar({ commit }, data) {
      commit('setShowSidebar', data);
    },
    setShowCompleted({ commit }, data) {
      commit('setShowCompleted', data);
    },
    setExpandView({ commit }, data) {
      commit('setExpandView', data);
    },
    addCollaborator({ commit }, data) {
      commit('addCollaborator', data);
    },
    removeCollaborator({ commit }, data) {
      commit('removeCollaborator', data);
    },
    disconnectCollaborator({ commit }, data) {
      commit('disconnectCollaborator', data);
    },
    setDarkMode({ commit }, data) {
      commit('setDarkMode', data);
    },
    addRecent({ commit }, data) {
      commit('addRecent', data);
    },
    updateAccountAvatar({ commit }, { key, blob }) {
      commit('updateAccountAvatar', { key, blob });
    },
    updateLastUpdated({ commit }, { oid, date }) {
      commit('updateLastUpdated', { oid, date });
    },
    updateOrgAvatar({ commit }, { key, blob }) {
      commit('updateOrgAvatar', { key, blob });
    },
    // New actions for devices
    setSelectedMicrophone({ commit }, deviceId) {
      commit('setSelectedMicrophone', deviceId);
    },
    setSelectedCamera({ commit }, deviceId) {
      commit('setSelectedCamera', deviceId);
    },
    setSelectedSpeaker({ commit }, deviceId) {
      commit('setSelectedSpeaker', deviceId);
    }
  },
});